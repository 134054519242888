import { template as template_682a30b38e4e425db490e89b1016c175 } from "@ember/template-compiler";
const FKLabel = template_682a30b38e4e425db490e89b1016c175(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
