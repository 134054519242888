import { template as template_823733295fe74e47bb4f6df6d1648c97 } from "@ember/template-compiler";
const FKControlMenuContainer = template_823733295fe74e47bb4f6df6d1648c97(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
