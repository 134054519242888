import { template as template_55f3687835ce4d499ed9fc8c7eb79344 } from "@ember/template-compiler";
const WelcomeHeader = template_55f3687835ce4d499ed9fc8c7eb79344(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
